'use strict'

const _ = require('lodash')
const PropTypes = require('prop-types')
const utils = require('santa-core-utils')
const collectFontsFromLoadedCompStyles = require('../utils/skins/collectFontsFromLoadedCompStyles')
const skinsRenderer = require('../utils/skins/skinRenderer')
const Touchy = require('../utils/Touchy')
const createReactElement = require('../utils/createReactElement')
const fixedPositionRenderPlugin = require('../utils/fixedPositionRenderPlugin')
const baseCompMixin = require('./baseCompMixin')
const santaTypesDefinitions = require('../definitions/santaTypesDefinitions')

skinsRenderer.registerRenderPlugin(fixedPositionRenderPlugin)

function getCompCssStates() {
    let state

    if (_.isFunction(this.getTransformedCssStates)) {
        const transformedCssStates = this.getTransformedCssStates()
        state = transformedCssStates !== undefined ? transformedCssStates : this.state
    } else {
        state = this.state
    }

    if (_.isFunction(this.getTransformedCssStatesForHeader)) {
        state = this.getTransformedCssStatesForHeader(state)
    }

    if (!state) {
        return {}
    }
    const stateAttribute = {}
    let cssState = []

    _.forOwn(state, function (stateValue, stateGroup) {
        if (stateGroup.lastIndexOf('$', 0) === 0) {
            cssState.push(stateValue)
        }
    })

    //should be removed when no component uses this options
    if (_.isEmpty(cssState) && state.hasOwnProperty('cssState')) {
        cssState = _.values(state.cssState)
    }

    if (!_.isEmpty(cssState)) {
        stateAttribute['data-state'] = cssState.join(' ')
    }

    return stateAttribute
}

function getSkinFromJson(skinName, skinsJson, reportMissingSkin) {
    if (_.isNil(skinName)) {
        return
    }

    let skin = skinsJson[skinName]

    if (!skin) {
        /**
         * svgshape skins are not loaded to the skinsJson
         */
        if (!_.startsWith(skinName, 'svgshape.')) {
            reportMissingSkin('Required skin wasn\'t loaded yet', skinName)
            skin = _(skinsJson).values().head()
        }
    }

    return skin
}

function getSkinFromCompSkinsJson(comp, compSkinsJson) {
    const reportMissingSkin = comp.props.logger.error
    let skinName = comp.props.skin
    let skin = getSkinFromJson(skinName, compSkinsJson, reportMissingSkin)
    if (!skin && comp.getDefaultSkinName) {
        skinName = comp.getDefaultSkinName()
        skin = getSkinFromJson(skinName, compSkinsJson, reportMissingSkin)
    }
    return skin
}

function getSkinFromSkinsMap(comp, skinsMap) {
    let skinName = comp.props.skin
    let skin = skinsMap.get(skinName, comp.props.isExperimentOpen)
    if (!skin && comp.getDefaultSkinName) {
        skinName = comp.getDefaultSkinName()
        skin = skinsMap.get(skinName, comp.props.isExperimentOpen)
    }
    return skin
}

function ensureCursorPointerForClickableSkinParts(refData) {
    //this is due to a bug in iOS that doesn't fire onClick
    // event for non-clickable elements like div/span
    // since we don't see the cursor, it's ok to put it on all elements
    _(refData).filter(function (skinPart) {
        return _.has(skinPart, 'onClick')
    }).forEach(function (skinPart) {
        skinPart.style = _.assign(skinPart.style || {}, {cursor: 'pointer'})
    })
}

function addHoverModeListeners(refData) {
    if (this.props.structure && this.props.structure.modes) {
        const hoverMode = _.find(this.props.structure.modes.definitions, {type: utils.siteConstants.COMP_MODES_TYPES.HOVER})
        if (hoverMode) {
            const compId = this.props.structure.id
            refData[''].onMouseEnter = onHoverModeActivate(this.props.activateModeById, compId, this.props.rootId, hoverMode.modeId)
            refData[''].onMouseLeave = onHoverModeDeactivate(this.props.deactivateModeById, compId, this.props.rootId, hoverMode.modeId)
        }
    }
}

function getModesByType(compStructure, modeType) {
    const modeDefinitions = _.get(compStructure, 'modes.definitions')
    return _.filter(modeDefinitions, {type: modeType})
}

function getScrollModes(compStructure) {
    return getModesByType(compStructure, 'SCROLL')
}

function getWidthModes(compStructure) {
    return _.sortBy(getModesByType(compStructure, 'WIDTH'), 'settings.width')
}

function addScrollModeListeners() {
    const scrollModes = getScrollModes(this.props.structure)
    if (scrollModes.length) {
        this.props.windowScrollEventAspect.registerCompScrollModes(this.props.id, this.props.rootId, scrollModes)
    }
}

function triggerMatchingWidthMode() {
    const compStructure = this.props.structure
    const widthModes = getWidthModes(compStructure)
    if (!widthModes.length) {
        return
    }
    const compId = compStructure.id
    const currentWidth = compStructure.layout.width
    const rootId = this.props.rootId
    const modeToActivate = _.findLast(widthModes, function (modeDef) {
        return modeDef.settings.width <= currentWidth
    })

    this.props.activateModeById(compId, rootId, modeToActivate.modeId)
}

function onHoverModeActivate(activateModeById, compId, pageId, modeId) {
    return function () {
        if (this.props.isMobileView) {
            return
        }
        return activateModeById(compId, pageId, modeId)
    }
}

function onHoverModeDeactivate(deactivateModeById, compId, pageId, modeId) {
    return function () {
        if (this.props.isMobileView) {
            return
        }
        return deactivateModeById(compId, pageId, modeId)
    }
}

function getDataPreviewStates() {
    const state = this.getComponentPreviewState()
    if (state) {
        return {'data-preview': state}
    }
}


function getRefData(skin) {
    let refData = this.getSkinProperties()

    if (_.isFunction(this.transformRefData)) {
        this.transformRefData(refData)
    }

    if (this.props.transformSkinProperties) {
        refData = this.props.transformSkinProperties(refData)
    }

    if (!refData['']) {
        refData[''] = {}
    }

    if (this.props.isMobileDevice) {
        ensureCursorPointerForClickableSkinParts(refData)
    }

    addHoverModeListeners.call(this, refData)

    if (this.props.isExperimentOpen('scrollModes')) {
        addScrollModeListeners.call(this)
    }

    if (this.props.isExperimentOpen('widthModes')) {
        triggerMatchingWidthMode.call(this)
    }

    this.updateRootRefDataStyles(refData[''])

    _.assign(refData[''], getCompCssStates.call(this), getDataPreviewStates.call(this))
    const {inlineContent} = refData
    if (inlineContent && (!skin.react || skin.react.length === 0)) {
        refData[''] = _.defaults(refData[''], _.has(inlineContent, 'props') ? {addChildren: [refData.inlineContent]} : inlineContent)
    }

    return refData
}

const getCompCss = (compSkinsJson, styleId, styleData, {themeData, mobileData, serviceTopology, reportMissingSkin}) => {
    const getSkin = compSkinsJson.get ? skinId => compSkinsJson.get(skinId) : skinId => getSkinFromJson(skinId, compSkinsJson, reportMissingSkin)
    const skinData = getSkin(styleData.skin)
    const styleProps = _.get(styleData, 'style.properties', {})

    return skinData ?
        {[styleId]: skinsRenderer.createSkinCss(skinData, styleProps, themeData, styleId, mobileData, serviceTopology, getSkin)} :
        null
}

const getCompFonts = (compSkinsJson, styleItems, {generalTheme, reportMissingSkin}) => {
    const getSkin = compSkinsJson.get ? skinId => compSkinsJson.get(skinId) : skinId => getSkinFromJson(skinId, compSkinsJson, reportMissingSkin)

    return collectFontsFromLoadedCompStyles(styleItems, generalTheme, getSkin)
}

/**
 * @class core.skinBasedComp
 * @extends {core.baseCompMixin}
 * @extends {ReactCompositeComponent}
 * @property {comp.properties} props
 * @property {function(): string} getDefaultSkinName
 * @property {function(): object} getSkinProperties
 */
function skinBasedComp(compSkinsJson) {
    const getSkin = compSkinsJson.get ? getSkinFromSkinsMap : getSkinFromCompSkinsJson
    return {
        mixins: [baseCompMixin.baseComp],

        statics: {
            getCompCss: _.partial(getCompCss, compSkinsJson),
            getCompFonts: _.partial(getCompFonts, compSkinsJson)
        },

        propTypes: {
            isTouchDevice: santaTypesDefinitions.Device.isTouchDevice,
            isMobileView: santaTypesDefinitions.isMobileView,
            isMobileDevice: santaTypesDefinitions.Device.isMobileDevice,
            isDebugMode: santaTypesDefinitions.isDebugMode,
            isQAMode: santaTypesDefinitions.isQAMode,
            hideComponentsListForQa: santaTypesDefinitions.hideComponentsListForQa,
            structure: santaTypesDefinitions.Component.structure,
            id: santaTypesDefinitions.Component.id,
            rootId: santaTypesDefinitions.Component.rootId,
            currentUrlPageId: santaTypesDefinitions.Component.currentUrlPageId,
            styleId: santaTypesDefinitions.Component.styleId,
            skin: santaTypesDefinitions.Component.skin,
            logger: santaTypesDefinitions.Utils.logger,
            style: santaTypesDefinitions.Component.style,
            compProp: santaTypesDefinitions.Component.compProp,
            compData: santaTypesDefinitions.Component.compData,
            compActions: santaTypesDefinitions.Component.compActions,
            componentPreviewState: santaTypesDefinitions.RenderFlags.componentPreviewState,
            getActiveModes: santaTypesDefinitions.Modes.getActiveModes,
            activateModeById: santaTypesDefinitions.Modes.activateModeById,
            deactivateModeById: santaTypesDefinitions.Modes.deactivateModeById,
            switchModesByIds: santaTypesDefinitions.Modes.switchModesByIds,
            windowScrollEventAspect: santaTypesDefinitions.SiteAspects.windowScrollEvent.isRequired,
            transformSkinProperties: PropTypes.func,
            isExperimentOpen: santaTypesDefinitions.isExperimentOpen,
            setCustomClickOccurred: santaTypesDefinitions.setCustomClickOccurred,
            // For render plugins
            renderFixedPosition: santaTypesDefinitions.Component.renderFixedPosition
        },

        renderHelper() {
            const skin = getSkin(this, compSkinsJson)

            if (!skin) {
                const componentName = this.constructor.displayName || ''
                this.props.logger.error(`Skin [${this.props.skin}] not found for comp [${componentName}]`)
                return createReactElement('div')
            }

            const refData = getRefData.call(this, skin)

            const touchy = new Touchy()

            _.forEach(refData, function (ref) {
                if (this.props.isTouchDevice) {
                    touchy.registerTouchEvents(ref)
                }

                // Prevent custom events from reaching the DOM
                touchy.removeCustomTouchEvents(ref)
            }.bind(this))

            return skinsRenderer.renderSkinHTML.call(this, skin.react, refData, this.props.styleId, this.props.id, this.props.structure, this.props, this.state, this.props.isQAMode, this.props.hideComponentsListForQa)
        },

        getComponentPreviewState() {
            return this.props.componentPreviewState
        },

        render() {
            try {
                return this.renderHelper()
            } catch (e) {
                if (this.props.isDebugMode) {
                    throw e
                }
                this.props.logger.error(`Cannot render component: ${this.constructor.displayName}`, e.stack, this.props.id)
                const deadCompProps = {
                    style: _.defaults({background: 'transparent'}, this.props.style),
                    'data-dead-comp': true
                }

                return createReactElement('div', deadCompProps)
            }
        },

        getSkinExports() {
            const skin = getSkin(this, compSkinsJson)
            return skin && skin.exports
        },

        classSet(classesMap) {
            return utils.classNames(_.reduce(classesMap, function (result, value, className) {
                result[`${this.styleId}_${className}`] = value
                return result
            }.bind(this.props), {}))
        },

        componentWillUpdate() {
            if (this.props.onComponentWillUpdate) {
                this.props.onComponentWillUpdate()
            }
        },

        componentWillUnmount() {
            if (this.props.windowScrollEventAspect) {
                this.props.windowScrollEventAspect.clearCompScrollModes(this.props.id)
            }
        }
    }
}

module.exports = skinBasedComp