'use strict'

const santaTypesDefinitions = require('./definitions/santaTypesDefinitions')
const components = require('./components/components')
const animatableMixin = require('./mixins/animatableMixin')
const animationsMixin = require('./mixins/animationsMixin')
const compActionMixin = require('./mixins/compActionMixin')
const compBehaviorsExecuterMixin = require('./mixins/compBehaviorsExecuterMixin')
const baseCompMixin = require('./mixins/baseCompMixin')
const inlineContentMixin = require('./mixins/inlineContentMixin')
const skinBasedComp = require('./mixins/skinBasedComp')
const fixedPositionContainerMixin = require('./mixins/fixedPositionContainerMixin')
const createReactElement = require('./utils/createReactElement')
const animationsQueueHandler = require('./utils/animationsQueueHandler')
const createChildComponentUtils = require('./utils/createChildComponentUtils')
const fixInvalidStyles = require('./utils/fixInvalidStyles')
const collectFontsFromLoadedCompStyles = require('./utils/skins/collectFontsFromLoadedCompStyles')
const skinRenderer = require('./utils/skins/skinRenderer')

module.exports = {
    santaTypesDefinitions,
    components,
    mixins: {
        animatableMixin,
        animationsMixin,
        compActionMixin,
        compBehaviorsExecuterMixin,
        baseCompMixin: baseCompMixin.baseComp,
        inlineContentMixin,
        skinBasedComp,
        fixedPositionContainerMixin
    },
    utils: {
        skinsRenderer: skinRenderer,
        collectFontsFromLoadedCompStyles,
        createReactElement,
        animationsQueueHandler,
        createChildComponentUtils,
        fixInvalidStyles
    }
}