'use strict'

const _ = require('lodash')
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')
const skinBasedComp = require('../../mixins/skinBasedComp')
const imageCommon = require('../../components/imageCommon/imageCommon')
const skinsJson = require('../../components/image/imageSkins/skins.json.js')

const imageElements = imageCommon.imageElements

const getComponentSkins = () => skinsJson

function getStyleString(styleObj) {
    return _.transform(styleObj, function (result, value, key) {
        result.push(`${_.kebabCase(key)}:${value}`)
        return result
    }, []).join(';')
}

function filterProps(props) {
    const allowed = _.keyBy(['onKeyDown', 'onKeyPress', 'onKeyUp', 'onFocus', 'onBlur', 'onChange', 'onInput', 'onSubmit', 'onClick', 'onContextMenu',
        'onDoubleClick', 'onDrag', 'onDragEnd', 'onDragEnter', 'onDragExit', 'onDragLeave', 'onDragOver', 'onDragStart', 'onDrop', 'onMouseDown',
        'onMouseEnter', 'onMouseLeave', 'onMouseMove', 'onMouseOut', 'onMouseOver', 'onMouseUp', 'onSelect', 'onTouchCancel', 'onTouchEnd',
        'onTouchMove', 'onTouchStart', 'onScroll', 'onWheel', 'onAbort', 'onCanPlay', 'onCanPlayThrough', 'onDurationChange', 'onEmptied', 'onEncrypted',
        'onEnded', 'onError', 'onLoadedData', 'onLoadedMetadata', 'onLoadStart', 'onPause', 'onPlay', 'onPlaying', 'onProgress', 'onRateChange', 'onSeeked',
        'onSeeking', 'onStalled', 'onSuspend', 'onTimeUpdate', 'onVolumeChange', 'onWaiting', 'onAnimationStart', 'onAnimationEnd', 'onAnimationIteration',
        'onTransitionEnd', 'onCompositionEnd', 'onCompositionStart', 'onCompositionUpdate', 'onCopy', 'onCut', 'onPaste'])


    return _.reduce(props, function (res, value, prop) {
        if (_.startsWith(prop, 'data-') || _.startsWith(prop, 'aria-') || _.has(allowed, prop)) {
            res[prop] = value
        }
        return res
    }, {})
}

/**
 * @class components.ZoomedImage
 */

const Image = createReactClass({
    displayName: 'Image',

    mixins: [skinBasedComp(getComponentSkins())],

    propTypes: {
        id: PropTypes.string,
        staticMediaUrl: santaTypesDefinitions.ServiceTopology.staticMediaUrl,
        reportBI: santaTypesDefinitions.reportBI,
        cssFiltersSupported: santaTypesDefinitions.BrowserFlags.cssFiltersSupported.isRequired,
        currentUrl: santaTypesDefinitions.currentUrl,
        onImageUnmount: santaTypesDefinitions.Images.onImageUnmount,
        getMediaFullStaticUrl: santaTypesDefinitions.ServiceTopology.getMediaFullStaticUrl,
        devicePixelRatio: santaTypesDefinitions.Device.devicePixelRatio,
        containerWidth: PropTypes.number.isRequired,
        containerHeight: PropTypes.number.isRequired,
        imageData: PropTypes.object.isRequired,
        style: PropTypes.object,
        quality: PropTypes.object,
        opacity: PropTypes.number,
        alignType: PropTypes.string,
        displayMode: PropTypes.string,
        fittingType: PropTypes.string,
        filterEffect: PropTypes.object,
        addItemProp: PropTypes.bool,
        imgStyle: PropTypes.object,
        'data-gallery-id': PropTypes.string,
        'data-page-desc': PropTypes.string,
        'data-query': PropTypes.string,
        'data-image-index': PropTypes.number,
        'data-type': PropTypes.string,
        onMouseEnter: PropTypes.func,
        onTouchStart: PropTypes.func,
        autoLayout: PropTypes.bool
    },

    statics: {
        useSantaTypes: true,
        getComponentSkins
    },

    getInitialState() {
        return {
            showPreloader: false
        }
    },

    componentWillUnmount() {
        this.props.onImageUnmount(this.props.id)
    },


    hasEffect() {
        return this.props.filterEffect && this.props.filterEffect.effectType !== 'none'
    },

    onImageMouseEnter() {
        if (typeof this.props.onMouseEnter === 'function') {
            this.props.onMouseEnter(this.props.id)
        }
    },

    onImageTouchStart() {
        if (typeof this.props.onTouchStart === 'function') {
            this.props.onTouchStart(this.props.id)
        }
    },

    getDefaultStyles(style) {
        return _.pickBy(style, function (value) {
            return value !== ''
        })
    },

    getContainerStyle(imageComputedProperties) {
        const styleWithoutDefaults = this.getDefaultStyles(this.props.style)
        const containerCss = imageComputedProperties.css.container

        const containerStyle = _.assign({
            position: 'absolute',
            width: this.props.containerWidth,
            height: this.props.containerHeight
        }, containerCss, styleWithoutDefaults)

        if (this.hasEffect()) {
            containerStyle.WebkitTransform = 'translateZ(0)'
            containerStyle.transform = 'translateZ(0)'
        }
        if (_.isNumber(this.props.imageData.opacity)) {
            containerStyle.opacity = this.props.imageData.opacity
        }

        return containerStyle
    },

    updateRootStyle(rootProps) {
        const styleAttributesToRender = ['visibility', 'transform'] // Breaks animations if moved to layout (CLNT-9508)
        const styleToRender = _.pick(this.props.style, styleAttributesToRender)
        const styleForLayout = _.omit(this.props.style, styleAttributesToRender)

        if (this.hasEffect()) {
            styleToRender.WebkitTransform = 'translateZ(0)'
            styleToRender.transform = 'translateZ(0)'
        }

        _.assign(rootProps, {
            style: styleToRender,
            'data-style': getStyleString(styleForLayout)
        })
    },

    getSkinProperties() {
        const altText = _.get(this, 'props.imageData.alt', '')
        // For IE/Edge
        const isSvgFallbackForFilters = this.hasEffect() && !this.props.cssFiltersSupported

        const imageComponents = imageElements.getImageComponents(this.props, altText, isSvgFallbackForFilters)

        const rootProps = _.defaults({
            addChildren: imageComponents
        }, this.props.autoLayout && {'data-image-info': JSON.stringify(_.pick(this.props, ['imageData', 'alignType', 'displayMode']))},
        {
            onMouseEnter: this.onImageMouseEnter,
            onTouchStart: this.onImageTouchStart
        },
        filterProps(this.props))

        this.updateRootStyle(rootProps)

        return {
            '': rootProps
        }
    },

    getDefaultSkinName() {
        return 'skins.core.ImageNewSkinZoomable'
    }
})

module.exports = Image