'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const createReactClass = require('create-react-class')
const {baseComp} = require('../../mixins/baseCompMixin')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')

const AppWidget = createReactClass({
    displayName: 'AppWidget',

    mixins: [baseComp],

    propTypes: {
        children: PropTypes.node,
        style: santaTypesDefinitions.Component.style.isRequired,
        marginLeft: PropTypes.string
    },

    statics: {
        getCompCss() {
            return null
        }
    },

    render() {
        const style = _.defaults({marginLeft: this.props.marginLeft, overflow: 'hidden'}, this.props.style)
        return React.createElement('div', {style}, this.props.children)
    }
})

module.exports = AppWidget
