(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("prop-types"), require("create-react-class"), require("coreUtilsLib"), require("react"), require("reactDOM"), require("zepto"), require("imageClientLib"), require("skinUtils"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "prop-types", "create-react-class", "coreUtilsLib", "react", "reactDOM", "zepto", "imageClientLib", "skinUtils"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("lodash"), require("prop-types"), require("create-react-class"), require("coreUtilsLib"), require("react"), require("reactDOM"), require("zepto"), require("imageClientLib"), require("skinUtils")) : factory(root["lodash"], root["prop-types"], root["create-react-class"], root["coreUtilsLib"], root["react"], root["reactDOM"], root["zepto"], root["imageClientLib"], root["skinUtils"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__34__, __WEBPACK_EXTERNAL_MODULE__47__, __WEBPACK_EXTERNAL_MODULE__58__) {
return 