'use strict'

const _ = require('lodash')
const PropTypes = require('prop-types')
const createReactClass = require('create-react-class')
const createReactElement = require('../../utils/createReactElement')
const mesh = require('santa-mesh')
const React = require('react')

const filterMeshParams = (params, predicate) =>
    predicate ? _.defaults({components: _.filter(params.components, predicate)}, params) : params

const extractMarginTop = ({margin = '0 0 0 0', marginTop}) => marginTop || margin.match(/([^ ]*) /)[1]

const getAdjustment = ({preAdjustmentLayout, adjustment, style}) => {
    const height = preAdjustmentLayout.height === adjustment.height ? null : adjustment.height
    const topDiff = _.isNumber(adjustment.top) && preAdjustmentLayout.top === adjustment.top ? null : Math.floor(adjustment.top - preAdjustmentLayout.top)
    const marginTop = topDiff ? parseInt(extractMarginTop(style), 10) + topDiff : null
    return {height, marginTop}
}

const stylePropName = key => _.head(key) === '-' ? key : _.kebabCase(key)

const getChildrenMap = reactChildren => 
    _.reduce(reactChildren, (agg, reactComp, index) => _.defaults({[reactComp.props.id]: {reactComp, index}}, agg), {})
const getPropFromMap = prop => (map, compId, defaultValue) => _.get(map, [compId, prop], defaultValue)
const getCompFromChildrenMap = getPropFromMap('reactComp')
const getIndexFromChildrenMap = getPropFromMap('index')

function getChildrenToRender({id, meshResult, adjustment, childrenById}) {
    const {styles, structure} = meshResult
    const resultStyle = _.merge({}, styles, adjustment && {[adjustment.id]: _(adjustment.style).omitBy(_.isNil).mapValues(s => `${s}px`).value()})

    const styleElementID = `${id}-mesh-styles`
    const styleElement = createReactElement('style', {
        key: styleElementID,
        id: styleElementID,
        dangerouslySetInnerHTML: {__html: `
    ${_(resultStyle).map((compStyle, compId) => `
#${compId === id ? `${id}inlineContent` : compId} {
${_(compStyle).omitBy(_.isNil).map((value, key) => `    ${stylePropName(key)}: ${value};`).join('\n')}
}`).join('\n')}
#${id}centeredContent { position: relative; }
#${id}inlineContent { position: relative; }
    `}})

    const getMeshChildrenRecursively = structureChildren =>
        _(structureChildren)
            .sortBy(child => getIndexFromChildrenMap(childrenById, child.id))
            .map(({id: childId, children: grandchildren}) =>
                getCompFromChildrenMap(childrenById, childId,
                    createReactElement('div', {id: childId, key: childId, 'data-mesh-internal': true},
                        getMeshChildrenRecursively(grandchildren))))
            .value()

    return [styleElement, ...getMeshChildrenRecursively(structure.children)]
}

const InlineContent = createReactClass({
    displayName: 'InlineContent',
    propTypes: {
        children: PropTypes.array,
        inlineContentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        className: PropTypes.string,
        addChildrenBefore: PropTypes.array,
        fixedChildrenIds: PropTypes.array,
        isMeshLayoutMechanism: PropTypes.bool,
        isQAMode: PropTypes.bool,
        'data-aid': PropTypes.string,
        meshParams: PropTypes.object,
        filterChildren: PropTypes.func,
        id: PropTypes.string.isRequired,
        cssGridVariant: PropTypes.string,
        style: PropTypes.object
    },
    getInitialState: () => ({}),
    statics: {
        getDerivedStateFromProps({id, isMeshLayoutMechanism, fixedChildrenIDs, children = [], meshParams, cssGridVariant = 'standard', addChildrenBefore = [], filterChildren = () => true}, prevState) {
            const childrenArray = React.Children.toArray(children)

            if (!isMeshLayoutMechanism) {
                return {children: [...addChildrenBefore, ...childrenArray]}
            }

            const state = prevState || {}
            if (_.isEqual(meshParams, state.meshParams)) {
                return null
            }

            const adjustment = meshParams.adjustingComp && _.find(meshParams.components, {id: meshParams.adjustingComp})
            const meshResult = adjustment ?
                state.meshResult :
                mesh.structure2mesh(filterMeshParams(meshParams, comp => filterChildren(comp.id) && !comp.isFixed), {cssGridVariant})
            const preAdjustmentLayout = adjustment ? state.preAdjustmentLayout || _.find(state.meshParams.components, {id: adjustment.id}) : null
            const adjustmentStyle = adjustment && getAdjustment({
                adjustment, preAdjustmentLayout,
                style: meshResult.styles[adjustment.id]
            })
            
            const childrenById = getChildrenMap(childrenArray)
            const getComp = getCompFromChildrenMap.bind(null, childrenById)
            const fixedChildren = fixedChildrenIDs.map(getComp)

            return {meshParams, meshResult, preAdjustmentLayout,
                children: [...addChildrenBefore, ...fixedChildren,
                    ...getChildrenToRender({
                        id,
                        childrenById,
                        meshResult,
                        adjustment: adjustmentStyle && {id: adjustment.id, style: adjustmentStyle}
                    })]}
        }
    },

    render() {
        return createReactElement('div', _.assign({
            id: `${this.props.id}inlineContent`,
            className: this.props.className,
            key: 'inlineContent',
            style: this.props.style,
            ref: this.props.inlineContentRef
        },
        this.props.isQAMode && {'data-skinpart': 'inlineContent', 'data-aid': this.props['data-aid']},
        this.props.style && {style: this.props.style}), this.state.children)
    }
})

const forwardRef = (props, inlineContentRef) => createReactElement(InlineContent, _.assign({inlineContentRef}, props))
module.exports = React.forwardRef(forwardRef)
