'use strict'

const _ = require('lodash')
const santaTypesDefinitions = require('../definitions/santaTypesDefinitions')

function generateParamArray(behaviorDef, behavior) {
    const paramArr = _.map(behaviorDef.params, function (paramDef) {
        if (_.isString(paramDef)) {
            return behavior.params[paramDef]
        }
        return _.get(behavior.params, paramDef.name, paramDef.defaultValue)
    })
    return behavior.callback ? paramArr.concat(behavior.callback) : paramArr
}

function executeBehaviorMethods(compDynamicBehaviors) {
    _.forEach(compDynamicBehaviors, function (behavior) {
        const behaviorDef = _.get(this.constructor, ['behaviors', behavior.name]) || _.get(this.constructor, ['behaviorExtensions', behavior.name], {})
        if (_.isFunction(this[behaviorDef.methodName])) {
            const paramArray = generateParamArray(behaviorDef, behavior)
            this[behaviorDef.methodName].apply(this, paramArray)
        }
    }.bind(this))
}

module.exports = {
    propTypes: {
        compDynamicBehaviors: santaTypesDefinitions.Component.compDynamicBehaviors,
        compDynamicBehaviorsClearAfterFlush: santaTypesDefinitions.Component.compDynamicBehaviorsClearAfterFlush
    },

    getDefaultProps() {
        return {
            compDynamicBehaviorsClearAfterFlush: _.noop
        }
    },

    componentWillMount() {
        executeBehaviorMethods.call(this, this.props.compDynamicBehaviors)//what if the method needs the dom?
    },

    componentWillReceiveProps(nextProps) {
        executeBehaviorMethods.call(this, nextProps.compDynamicBehaviors)
    },

    componentDidUpdate() {
        this.props.compDynamicBehaviorsClearAfterFlush()
    },

    componentDidMount() {
        this.props.compDynamicBehaviorsClearAfterFlush()
    }
}