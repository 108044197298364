'use strict'

const _ = require('lodash')
const React = require('react')
const createReactElement = require('../utils/createReactElement')
const InlineContent = require('../components/InlineContent/InlineContent')
const santaTypesDefinitions = require('../definitions/santaTypesDefinitions')

module.exports = {
    statics: {
        meshContainer: true
    },

    propTypes: {
        id: santaTypesDefinitions.Component.id,
        fixedChildrenIDs: santaTypesDefinitions.Component.fixedChildrenIDs,
        isQAMode: santaTypesDefinitions.isQAMode,
        meshParams: santaTypesDefinitions.Component.meshParams,
        styleId: santaTypesDefinitions.Component.styleId,
        isMeshLayoutMechanism: santaTypesDefinitions.Layout.isMeshLayoutMechanism,
        isPreviewMode: santaTypesDefinitions.isPreviewMode,
        isExperimentOpen: santaTypesDefinitions.isExperimentOpen,
        siteWidth: santaTypesDefinitions.siteWidth,
        browser: santaTypesDefinitions.Browser.browser,
        isMobileView: santaTypesDefinitions.isMobileView
    },

    getDefaultBackgroundStyle() {
        return this.props.isMobileView ? {} : {marginLeft: `calc((100% - ${this.props.siteWidth}px) / 2)`, width: this.props.siteWidth}
    },

    getDefaultContentArea() {
        return {alignment: 0.5, width: this.props.siteWidth}
    },

    hasContentArea(contentArea) {
        return contentArea && !this.props.isMobileView
    },

    getChildrenWithContentArea(contentArea) {
        if (!this.hasContentArea(contentArea) || this.props.isMeshLayoutMechanism) {
            return this.props.children
        }

        return React.Children.map(this.props.children, child =>
            React.cloneElement(child, {marginLeft: `calc((100% - ${contentArea.width}px) * ${contentArea.alignment})`}))
    },

    ignoreDimensions() {
        return this.props.isMeshLayoutMechanism
    },

    getMeasurerElements(contentArea) {
        if (!this.hasContentArea(contentArea) || !this.props.isPreviewMode) {
            return []
        }

        const {width, alignment} = contentArea

        return [createReactElement('div', {
            style: {
                visibility: 'hidden', position: 'absolute', width,
                marginLeft: `calc((100% - ${width}px) * ${alignment})`
            },
            key: 'content-area-measurer',
            'data-content-area-measurer': 'true',
            'data-aid': `content-area-measurer-${this.props.id}`
        })]
    },

    getInlineContent({overrides, contentArea, props, filterChildren} = {}) {
        const {id, meshParams, isMeshLayoutMechanism, fixedChildrenIDs, isQAMode, browser} = this.props
        const children = this.getChildrenWithContentArea(contentArea)
        const hasContentArea = this.hasContentArea(contentArea)
        const measurerElements = this.getMeasurerElements(contentArea)
        const cssGridVariant = browser.ie ? 'ms' : 'standard'

        const inlineContentProps = _.assign({
            filterChildren,
            isMeshLayoutMechanism,
            cssGridVariant,
            fixedChildrenIDs,
            id,
            isQAMode,
            children: children || [],
            addChildrenBefore: measurerElements || [],
            meshParams: _.assign(meshParams, overrides, hasContentArea && {width: '100%', contentArea}),
            key: 'inlineContent',
            ref: 'inlineContent'
        }, this.props.styleId && {className: `${this.props.styleId}inlineContent`}, props)

        return createReactElement(InlineContent, inlineContentProps)
    }
}
