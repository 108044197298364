/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['wysiwyg.viewer.skins.FiveGridLineSkin'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "clr": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "clr": "color_3"
  },
  "css": {
    "%": "border-bottom:2px solid [clr];min-height:5px;"
  }
}
 skins['wysiwyg.viewer.skins.line.ArrowLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {},
      [
        "div",
        null,
        [
          "_arr"
        ],
        {}
      ]
    ]
  ],
  "params": {
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brd": "color_15"
  },
  "css": {
    "%": "border-bottom:1px solid [brd];position:absolute;top:0;height:0 !important;min-height:0 !important;",
    "%_arr": "position:absolute;left:-5px;bottom:-5px;border-top:5px solid transparent;border-bottom:5px solid transparent;border-right:10px solid [brd];"
  }
}
 skins['wysiwyg.viewer.skins.line.ArrowRightLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {},
      [
        "div",
        null,
        [
          "_arr"
        ],
        {}
      ]
    ]
  ],
  "params": {
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brd": "color_15"
  },
  "css": {
    "%": "border-bottom:1px solid [brd];position:absolute;top:0;height:0 !important;min-height:0 !important;",
    "%_arr": "position:absolute;right:-5px;bottom:-5px;border-top:5px solid transparent;border-bottom:5px solid transparent;border-left:10px solid [brd];"
  }
}
 skins['wysiwyg.viewer.skins.line.DashedLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "exports": {
    "_maxHeight": 2
  },
  "params": {
    "lnw": "BORDER_BOTTOM_SIZE",
    "brd": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "lnw": "2px",
    "brd": "color_15"
  },
  "css": {
    "%": "border-bottom:[lnw] dashed [brd];height:0 !important;min-height:0 !important;"
  }
}
 skins['wysiwyg.viewer.skins.line.DottedLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "BORDER_BOTTOM_SIZE",
    "brd": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "lnw": "2px",
    "brd": "color_15"
  },
  "css": {
    "%": "border-bottom:[lnw] dotted [brd];height:0 !important;min-height:0 !important;"
  }
}
 skins['wysiwyg.viewer.skins.line.DoubleLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw1": "BORDER_TOP_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "lnw2": "BORDER_BOTTOM_SIZE",
    "dst": "PADDING_SIZE"
  },
  "paramsDefaults": {
    "lnw1": "3px",
    "brd": "color_15",
    "lnw2": "1px",
    "dst": "5px"
  },
  "css": {
    "%line": "border-top:[lnw1] solid [brd];border-bottom:[lnw2] solid [brd];padding:[dst] 0 0 0;"
  }
}
 skins['wysiwyg.viewer.skins.line.DoubleLine2'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {},
      [
        "div",
        null,
        [
          "_top"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_bottom"
        ],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_xxx"
      ],
      {}
    ]
  ],
  "exports": {
    "minH": 30
  },
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%_top": "border-bottom:1px solid #f00;margin:0 0 4px 0;height:0 !important;min-height:0 !important;",
    "%_bottom": "border-bottom:5px solid #f00;",
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;"
  }
}
 skins['wysiwyg.viewer.skins.line.DoubleLine3'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {},
      [
        "div",
        null,
        [
          "_top"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_bottom"
        ],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_xxx"
      ],
      {}
    ]
  ],
  "exports": {
    "minH": 30
  },
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%_top": "border-bottom:1px solid #f00;margin:0 0 3px 0;",
    "%_bottom": "border-bottom:1px solid #f00;",
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;"
  }
}
 skins['wysiwyg.viewer.skins.line.FadeLine'] = {
  "react": [
    [
      "div",
      "cont",
      [],
      {},
      [
        "div",
        "line",
        [],
        {},
        [
          "div",
          null,
          [
            "_left",
            "_ln"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_center",
            "_ln"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_right",
            "_ln"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%": "height:29px !important;min-height:29px !important;max-height:29px !important;",
    "%cont": "width:100%;overflow:hidden;position:relative;",
    "%line": "height:29px;min-height:29px;min-width:90px !important;",
    "%_ln": "height:29px;min-height:29px;background-image:url([tdr]fade_line.png);position:absolute;top:0;",
    "%_left": "background-position:0 0;width:45px;left:0;",
    "%_right": "background-position:100% 0;width:45px;right:0;",
    "%_center": "background-position:0 -29px;right:45px;left:45px;"
  }
}
 skins['wysiwyg.viewer.skins.line.FadeNotchBottomLine'] = {
  "react": [
    [
      "div",
      "cont",
      [],
      {},
      [
        "div",
        "line",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%cont": "width:100%;overflow:hidden;position:relative;",
    "%cont:after": "background-image:url([tdr]fadenotchlinenew.png);background-position:0 -60px;width:30px;height:30px;left:0;position:absolute;top:0;content:\"\";",
    "%cont:before": "background-image:url([tdr]fadenotchlinenew.png);background-position:100% -60px;width:30px;height:30px;right:0;position:absolute;top:0;content:\"\";",
    "%line": "background-image:url([tdr]fadenotchlinenew.png);background-position:0 100%;margin:0 30px;height:30px;"
  }
}
 skins['wysiwyg.viewer.skins.line.FadeNotchTopLine'] = {
  "react": [
    [
      "div",
      "cont",
      [],
      {},
      [
        "div",
        "line",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%cont": "width:100%;overflow:hidden;position:relative;",
    "%cont:after": "background-image:url([tdr]fadenotchlinenew.png);background-position:0 -30px;width:30px;height:30px;left:0;position:absolute;top:0;content:\"\";",
    "%cont:before": "background-image:url([tdr]fadenotchlinenew.png);background-position:100% -30px;width:30px;height:30px;right:0;position:absolute;top:0;content:\"\";",
    "%line": "background-image:url([tdr]fadenotchlinenew.png);background-position:0 0;background-repeat:repeat-x;margin:0 30px;height:30px;"
  }
}
 skins['wysiwyg.viewer.skins.line.FiveGridLine'] = {
  "react": [
    [
      "div",
      "lineCnt",
      [],
      {},
      [
        "div",
        "line",
        [],
        {}
      ]
    ],
    [
      "div",
      "leftKnobCnt",
      [],
      {},
      [
        "div",
        "leftKnob",
        [],
        {}
      ]
    ],
    [
      "div",
      "middleKnobCnt",
      [],
      {},
      [
        "div",
        "middleKnob",
        [],
        {}
      ]
    ],
    [
      "div",
      "rightKnobCnt",
      [],
      {},
      [
        "div",
        "rightKnob",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "webThemeDir": "URL"
  },
  "paramsDefaults": {
    "webThemeDir": "WEB_THEME_DIRECTORY"
  },
  "css": {
    "%": "position:relative;",
    "%lineCnt": "position:absolute;right:4px;top:50%;height:70%;margin-top:-4px;left:4px;cursor:n-resize;",
    "%line": "background-image:url([webThemeDir]line.png);background-repeat:repeat-x;width:100%;height:100%;",
    "%leftKnob": "background-image:url([webThemeDir]square.png);background-repeat:no-repeat;width:100%;height:100%;background-size:100% 100%;",
    "%middleKnob": "background-image:url([webThemeDir]square.png);background-repeat:no-repeat;width:100%;height:100%;background-size:100% 100%;",
    "%rightKnob": "background-image:url([webThemeDir]square.png);background-repeat:no-repeat;width:100%;height:100%;background-size:100% 100%;",
    "%middleKnobCnt": "position:absolute;top:50%;left:50%;margin-top:-4px;margin-left:-4px;width:9px;height:70%;",
    "%leftKnobCnt": "position:absolute;top:50%;left:4px;margin-top:-4px;width:9px;height:70%;",
    "%rightKnobCnt": "position:absolute;top:50%;margin-top:-4px;right:2px;width:9px;height:70%;"
  }
}
 skins['wysiwyg.viewer.skins.line.IronLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "HEIGHT_SIZE",
    "clr": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "lnw": "6px",
    "clr": "color_15"
  },
  "css": {
    "%line": "min-height:2px;height:[lnw];border-radius:7px;background-color:[clr];box-shadow:0 1px 0 rgba(255, 255, 255, 0.1), inset 0 1px 0 rgba(0, 0, 0, 0.25);"
  }
}
 skins['wysiwyg.viewer.skins.line.NotchDashedLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "css": {
    "%line": "border-bottom:1px dashed rgba(255, 255, 255, 0.5);border-top:1px dashed rgba(0, 0, 0, 0.35);height:0 !important;"
  }
}
 skins['wysiwyg.viewer.skins.line.NotchLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "css": {
    "%line": "border-bottom:1px solid rgba(255, 255, 255, 0.35);border-top:1px solid rgba(0, 0, 0, 0.35);height:0 !important;"
  }
}
 skins['wysiwyg.viewer.skins.line.ShadowBottomLine'] = {
  "react": [
    [
      "div",
      "cont",
      [],
      {},
      [
        "div",
        "line",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%cont": "width:100%;overflow:hidden;position:relative;",
    "%cont:after": "background-image:url([tdr]fade_line.png);background-position:0 -87px;height:29px;left:0;position:absolute;top:0;width:100px;content:\"\";",
    "%cont:before": "background-image:url([tdr]fade_line.png);background-position:100% -87px;height:29px;right:0;position:absolute;top:0;width:100px;content:\"\";",
    "%line": "background-image:url([tdr]fade_line.png);background-position:0 58px;margin:0 100px;height:29px;"
  }
}
 skins['wysiwyg.viewer.skins.line.ShadowTopLine'] = {
  "react": [
    [
      "div",
      "cont",
      [],
      {},
      [
        "div",
        "line",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%cont": "width:100%;overflow:hidden;position:relative;",
    "%cont:after": "background-image:url([tdr]fade_line.png);background-position:0 0;height:29px;left:0;position:absolute;top:0;width:100px;content:\"\";",
    "%cont:before": "background-image:url([tdr]fade_line.png);background-position:100% 0;height:29px;right:0;position:absolute;top:0;width:100px;content:\"\";",
    "%line": "background-image:url([tdr]fade_line.png);background-position:0 -29px;margin:0 100px;height:29px;"
  }
}
 skins['wysiwyg.viewer.skins.line.SloppyLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%line": "background-image:url([tdr]header-line.png);background-repeat:repeat-x;height:3px;min-height:3px;"
  }
}
 skins['wysiwyg.viewer.skins.line.SolidLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "BORDER_BOTTOM_SIZE",
    "brd": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "lnw": "2px",
    "brd": "color_15"
  },
  "css": {
    "%": "border-bottom:[lnw] solid [brd];height:0 !important;min-height:0 !important;"
  }
}
 skins['wysiwyg.viewer.skins.line.ZigzagLineFlipSkin'] = {
  "react": [
    [
      "div",
      "line",
      [
        "_zigzag"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_filler"
      ],
      {}
    ]
  ],
  "params": {
    "zigzagsize": "BG_SIZE",
    "brd": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "zigzagsize": "10px",
    "brd": "color_15"
  },
  "css": {
    "%": "height:[zigzagsize];",
    "%_zigzag": "width:100%;position:relative;top:-50%;height:[zigzagsize];background-image:linear-gradient(135deg, transparent [zigzagsize], [brd] [zigzagsize]), linear-gradient(-135deg, transparent [zigzagsize], [brd] [zigzagsize]);background-size:[zigzagsize] [zigzagsize];background-repeat:repeat-x;background-position:50% 50%;",
    "%_filler": "width:100%;position:relative;bottom:50%;height:50%;background-color:[brd];"
  }
}
 skins['wysiwyg.viewer.skins.line.ZigzagLineSkin'] = {
  "react": [
    [
      "div",
      null,
      [
        "_filler"
      ],
      {}
    ],
    [
      "div",
      "line",
      [
        "_zigzag"
      ],
      {}
    ]
  ],
  "params": {
    "zigzagsize": "BG_SIZE",
    "brd": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "zigzagsize": "10px",
    "brd": "color_15"
  },
  "css": {
    "%": "height:[zigzagsize];",
    "%_zigzag": "width:100%;height:[zigzagsize];background-image:linear-gradient(45deg, transparent [zigzagsize], [brd] [zigzagsize]), linear-gradient(-45deg, transparent [zigzagsize], [brd] [zigzagsize]);background-size:[zigzagsize] [zigzagsize];background-repeat:repeat-x;background-position:50% 50%;",
    "%_filler": "width:100%;height:50%;background-color:[brd];"
  }
}

module.exports = skins