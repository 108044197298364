'use strict'

module.exports = [
    'hideComponentsListForQa',
    'activateModeById',
    'addCompAction',
    'addModeDefinition',
    'addModeOverride',
    'addSiteData',
    'animationProperties',
    'animations',
    'clickAction',
    'compActions',
    'compBehaviors',
    'compData',
    'compDynamicBehaviors',
    'compDynamicBehaviorsClearAfterFlush',
    'compProp',
    'componentOpacity',
    'componentPreviewState',
    'componentViewMode',
    'contextProps',
    'cookie',
    'currentUrl',
    'currentUrlPageId',
    'deactivateModeById',
    'displayName',
    'externalScriptLoader',
    'fieldType',
    'forType',
    'formatName',
    'functionLibrary',
    'getActiveModes',
    'getRootIdsWhichShouldBeRendered',
    'getTransitionParams',
    'goToLinkText',
    'handleAction',
    'shouldHideAnimatable',
    'isAffectedByModeChanges',
    'isCollapsed',
    'isDebugMode',
    'isExperimentOpen',
    'isMobileDevice',
    'isMobileView',
    'isQAMode',
    'isSiteBusy',
    'isTouchDevice',
    'linkData',
    'logger',
    'logic',
    'logicEvents',
    'now',
    'pageId',
    'pageStub',
    'parentContextPath',
    'parentId',
    'pathToItems',
    'playerId',
    'position',
    'proxyLayout',
    'reLayoutIfPending',
    'refInParent',
    'registerLayoutFunc',
    'registerReLayout',
    'registerReLayoutPending',
    'renderFixedPosition',
    'rootId',
    'rootNavigationInfo',
    'rootWidth',
    'rotationInDegrees',
    'setCompData',
    'setCompProp',
    'setCustomClickOccurred',
    'setLayout',
    'setNodeStyle',
    'setProps',
    'setRuntimeCompData',
    'setRuntimeCompProps',
    'setSkin',
    'setThemeStyle',
    'siteWidth',
    'shouldHideComponent',
    'shouldShowComponentOnTop',
    'siteAPI',
    'siteData',
    'skin',
    'skinId',
    'skinsMap',
    'structure',
    'styleId',
    'switchModesByIds',
    'userLanguage',
    'viewContextMap',
    'viewDef',
    'viewId',
    'viewName',
    'viewProps',
    'visibilityClasses',
    'visibilityState',
    'windowScrollEventAspect',
    'wixComp'
    // 'allowTransparency',
]
